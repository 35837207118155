<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.73 0H5.27L0 5.27V12.73L5.27 18H12.73L18 12.73V5.27L12.73 0ZM16 11.9L11.9 16H6.1L2 11.9V6.1L6.1 2H11.9L16 6.1V11.9ZM11.83 4.76L9 7.59L6.17 4.76L4.76 6.17L7.59 9L4.76 11.83L6.17 13.24L9 10.41L11.83 13.24L13.24 11.83L10.41 9L13.24 6.17L11.83 4.76Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcDanger',
  props: {
    size: {
      type: [String, Number],
      default: 18,
    },
  },
}
</script>

<style scoped lang="scss"></style>
